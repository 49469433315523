<template>
  <div class="NovaCotacao">
    <div class="NovaCotacao">
      <topoInterno
        titulo="Pré Cotação"
        @OpenMenuLateral="menuOpen = true"
      ></topoInterno>
      <div v-if="carregando == true" class="carregando">
        <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div
        class="topoForm"
        :style="`color:${temaCor.tituloPrimario}; border-left-color:${temaCor.tituloPrimario}`"
      >
        {{ verificaPasso() }}
      </div>
      <div class="conteudoInterno text-center mt-5 p-3 text-secondary">
        <b-form
          @submit.stop.prevent="onSubmit"
          @reset="onReset"
          class="text-left"
        >
          <!-- ------------Star Form Dados Pessoais-------------- -->
          <div v-show="passo == 0">
            <b-form-group id="input-group-2" label="Nome" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.nome"
                name="Nome"
                v-validate="{ required: true }"
                :state="validateState('Nome')"
                :class="validateState('Nome')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Celular"
              label-for="input-2"
            >
              <the-mask
                id="input-2"
                class="form-control"
                v-model="form.celular"
                name="Celular"
                :masked="true"
                mask="(##) # ####-####"
                v-validate="{ required: true, min: 16 }"
                :state="validateState('Celular')"
                :class="validateClass('Celular')"
              ></the-mask>
            </b-form-group>
            <b-form-group id="input-group-2" label="Email" label-for="input-2">
              <b-form-input
                id="Email"
                name="Email"
                v-model="form.email"
                v-validate="{ email: true }"
                :state="validateState('Email')"
                :class="validateClass('Email')"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Estado" label-for="input-2">
              <b-select
                :options="estados"
                name="Estado"
                id="Estado"
                v-model="estado"
                @change="buscaDadosSelectsCidades"
                v-validate="{ required: true }"
                :state="validateState('Estado')"
                :class="validateClass('Estado')"
              ></b-select>
            </b-form-group>
            <b-form-group id="input-group-2" label="Cidade" label-for="input-2">
              <b-select
                :options="cidades"
                name="Cidade"
                id="Cidade"
                v-model="form.cidade"
                v-validate="{ required: true }"
                :state="validateState('Cidade')"
                :class="validateClass('Cidade')"
              ></b-select>
            </b-form-group>
          </div>
          <!-- ---------------End Form Dados Pessoas---------------- -->

          <!------------------Start Form Dados do Veículo---------------->
          <div v-if="passo === 1">
            <b-form-group id="input-group-4">
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="temPlaca"
                switch
                >Tenho a placa do veículo</b-form-checkbox
              >
            </b-form-group>
            <b-form-group
              v-if="temPlaca"
              id="input-group-2"
              label="Placa"
              label-for="input-2"
            >
              <the-mask
                mask="AAA-#XX#"
                style="text-transform: uppercase"
                id="input-2"
                class="form-control"
                v-model="form.placa"
                name="Placa"
                :masked="true"
                @input="keymonitorPlaca"
                v-validate="{ required: true, length: 8 }"
                :state="validateState('Placa')"
                :class="validateClass('Placa')"
              />
            </b-form-group>
            <b-form-group
              v-if="temPlaca"
              id="input-group-2"
              label="Tipo do Veículo"
              label-for="input-2"
            >
              <b-form-select
                :options="tiposFipe"
                v-model="tipoFipe"
                @change="abrirModalGrupos(tipoFipe)"
                id="Tipo do Veículo"
                name="Tipo do Veículo"
                v-validate="{ required: true }"
                :state="validateState('Tipo do Veículo')"
                :class="validateClass('Tipo do Veículo')"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="temPlaca"
              id="input-group-2"
              label="Grupo do Veículo"
              label-for="input-2"
            >
              <b-row>
                <b-col :cols="temPlaca && escolherGrupoComPlaca ? 9 : 12">
                  <b-form-input
                    id="Grupo do Véiculo"
                    name="Grupo do Véiculo"
                    v-model="form.idGrupo.label"
                    v-validate="{ required: true }"
                    :state="validateState('Grupo do Véiculo')"
                    :class="validateState('Grupo do Véiculo')"
                    :disabled="true"
                  />
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex justify-content-end"
                  v-if="temPlaca && escolherGrupoComPlaca"
                >
                  <b-button
                    @click="mostrarModalGrupos = true"
                    pill
                    variant="success"
                    >{{ acaoBotao }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group v-if="temPlaca && showCilindrada" class="mt-3">
              <label>Cilindradas</label>
              <b-form-input
                type="number"
                v-model="form.veiculo.cilindradas"
                v-validate="{ required: true, min: 1 }"
                id="Cilindradas"
                name="Cilindradas"
                :state="validateState('Cilindradas')"
              ></b-form-input>
            </b-form-group>
            <b-card
              v-if="
                temPlaca &&
                mostrarModelosFipeEncontrados &&
                Object.hasOwn(dadosPlaca, 'marcaModelo')
              "
              :title="`${dadosPlaca.marcaModelo} - ${dadosPlaca.ano}/${dadosPlaca.anoModelo}`"
            >
              <div class="bg-secondary text-light p-3 mb-3">
                <b>Situação</b> {{ dadosPlaca.situacao }}
              </div>
              <div class="p-3 mb-3">
                Encontramos {{ modelosFipeEncontrados.length }} modelos que se
                assemelham ao seu veículo, selecione o Modelo correto.
              </div>
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in modelosFipeEncontrados"
                  :key="index"
                  :variant="`${
                    modeloSeleciondo.codigo_fipe === item.codigo_fipe
                      ? 'success'
                      : ''
                  }`"
                >
                  <b-row>
                    <b-col>
                      <b-form-radio v-model="modeloSeleciondo" :value="item"
                        >{{ item.texto_modelo }}
                        <b-badge variant="primary">{{
                          item.codigo_fipe
                        }}</b-badge
                        ><b-badge variant="warning">{{ item.score }}</b-badge>
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
              <b-row class="mt-3">
                <b-col cols="12">
                  <b-button
                    @click="
                      (temPlaca = false),
                        (mostrarModelosFipeEncontrados = false)
                    "
                    size="lg"
                    variant="danger"
                    class="btn btn-block"
                    >Nenhum Desses</b-button
                  ></b-col
                >
              </b-row>
            </b-card>
            <b-card
              title="Valores de Cobertura"
              v-if="temPlaca && mostrarValoresFipe"
            >
              <b-form-group
                id="input-group-2"
                label="Preço FIPE"
                label-for="input-2"
              >
                <money
                  class="form-control"
                  name="Preço FIPE"
                  id="Preço FIPE"
                  placeholder="Valor da Nota"
                  v-model="form.veiculo.resultFipe.preco"
                  v-validate="{ required: true, min_value: 0.01 }"
                  :state="validateState('Preço FIPE')"
                  :danger="validateClass('Preço FIPE')"
                  v-bind="money"
                  :disabled="true"
                ></money>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Valor de Cobertura"
                label-for="input-2"
              >
                <money
                  class="form-control"
                  name="Valor de Cobertura"
                  id="Valor de Cobertura"
                  placeholder="Valor da Nota"
                  v-model="form.valorCobertura"
                  v-validate="{ required: true, min_value: 0.01 }"
                  :state="validateState('Valor de Cobertura')"
                  :danger="validateClass('Valor de Cobertura')"
                  v-bind="money"
                  :disabled="true"
                ></money>
              </b-form-group>
            </b-card>
            <b-form-group
              v-if="!temPlaca"
              id="input-group-2"
              label="Valor do Veículo"
              label-for="input-2"
            >
              <money
                class="form-control"
                name="Valor do Veículo"
                id="Valor do Veículo"
                placeholder="Valor do Veículo"
                v-model="valorVeiculo"
                v-validate="{ required: true }"
                :state="validateState('Valor do Veículo')"
                :danger="validateClass('Valor do Veículo')"
                v-bind="money"
              ></money>
            </b-form-group>
            <b-form-group
              v-if="!temPlaca"
              id="input-group-2"
              label="Tipo do Veículo"
              label-for="input-2"
            >
              <b-form-select
                :options="tiposFipe"
                v-model="tipoFipe"
                @change="abrirModalGrupos(tipoFipe)"
                id="Tipo do Veículo"
                name="Tipo do Veículo"
                v-validate="{ required: true }"
                :state="validateState('Tipo do Veículo')"
                :class="validateClass('Tipo do Veículo')"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="!temPlaca"
              id="input-group-2"
              label="Grupo do Veículo"
              label-for="input-2"
            >
              <b-row>
                <b-col :cols="tipoFipe ? 9 : 12">
                  <b-form-input
                    id="Grupo do Véiculo"
                    name="Grupo do Véiculo"
                    v-model="form.idGrupo.label"
                    v-validate="{ required: true }"
                    :state="validateState('Grupo do Véiculo')"
                    :class="validateState('Grupo do Véiculo')"
                    :disabled="true"
                  />
                </b-col>
                <b-col
                  v-if="tipoFipe"
                  cols="3"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    @click="mostrarModalGrupos = true"
                    pill
                    variant="success"
                    >{{ acaoBotao }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group v-if="!temPlaca && showCilindrada" class="mt-3">
              <label>Cilindradas</label>
              <b-form-input
                type="number"
                v-model="form.veiculo.cilindradas"
                v-validate="{ required: true, min: 1 }"
                id="Cilindradas"
                name="Cilindradas"
                :state="validateState('Cilindradas')"
              ></b-form-input>
            </b-form-group>
          </div>
          <!------------------End Form dados do Veículo------------------>

          <!------------------Start Produtos--------------------------- -->
          <div v-if="passo == 2">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="produtosCotacao"
              name="flavour-2"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in produtosObrigatorios"
                  :key="index"
                >
                  <b-form-checkbox
                    class="w-100 mb-3"
                    :value="item"
                    size="lg"
                    :disabled="true"
                  >
                    <b class="mr-2">{{ item.nomeProduto }}</b>
                    <span
                      class="mr-2 valorprod"
                      v-if="item.visivelProposta"
                      :style="item.style"
                      >{{ item.valor | currency }}</span
                    >
                    <span
                      class="valorprod"
                      v-if="item.descontoConcedido > 0 && item.visivelProposta"
                      >{{ item.valorFinal | currency }}</span
                    >
                    <span
                      class="valorprod"
                      v-if="item.ismensal && item.visivelProposta"
                      >/mês</span
                    >
                  </b-form-checkbox>
                </b-list-group-item>
              </b-list-group>
            </b-form-checkbox-group>

            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="produtosCotacao"
              name="flavour-2"
              class="mt-4 mb-4"
            >
              <b-list-group>
                <b-list-group-item>
                  <h5
                    v-if="produtosOpcionais.length > 0"
                    class="my-2 text-center pt-2 pb-3"
                  >
                    Opcionais
                  </h5>
                </b-list-group-item>
                <b-list-group-item
                  v-for="(item, index) in produtosOpcionais"
                  :key="index"
                >
                  <b-form-checkbox
                    class="w-100 mb-3"
                    :value="item"
                    @click.native.prevent="processarProdutos(item)"
                    size="lg"
                  >
                    <b class="mr-2">{{ item.nomeProduto }}</b>
                    <span
                      class="mr-2 valorprod"
                      v-if="item.visivelProposta"
                      :style="item.style"
                      >{{ item.valor | currency }}</span
                    >
                    <span
                      class="valorprod"
                      v-if="item.descontoConcedido > 0 && item.visivelProposta"
                      >{{ item.valorFinal | currency }}</span
                    >
                    <span
                      class="valorprod"
                      v-if="item.ismensal && item.visivelProposta"
                      >/mês</span
                    >
                  </b-form-checkbox>
                </b-list-group-item>
              </b-list-group>
            </b-form-checkbox-group>
            <h6 v-if="produtosObrigatorios.length > 0">
              Total Mensal:
              <span class="valorprod">{{ somaValorTotalMes | currency }}</span>
            </h6>
            <h6 v-if="produtosObrigatorios.length > 0">
              Total Entrada:
              <span class="valorprod">{{ somaValorTotal | currency }}</span>
            </h6>
          </div>
          <!------------------End Produtos--------------------------- -->

          <!------------------Start Menu Navegação entre passos---------->
          <b-row class="mt-5">
            <b-col v-if="passo != 0" cols="2">
              <b-button variant="link text-dark" @click="voltar">
                <font-awesome-icon :icon="['fa', 'arrow-left']" />
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="passo != 2"
                pill
                ref="btnAvancar"
                size="lg"
                type="submit"
                variant="info"
                class="btn btn-block"
                :style="`background-color:${temaCor.botaoFormularios};`"
                >Avançar</b-button
              >
              <b-button
                v-if="passo === 2 && produtosObrigatorios.length > 0"
                pill
                ref="btnAvancar"
                size="lg"
                type="submit"
                variant="info"
                class="btn btn-block"
                :style="`background-color:${temaCor.botaoFormularios};`"
                @click="salvar"
                >Concluir</b-button
              >
            </b-col>
          </b-row>
          <!--------- End Menu Navegação entre passos ---------->
        </b-form>
      </div>
      <MenuLateral
        :decoded="decoded"
        :openMenuLateral="menuOpen"
        @closeMenuLateral="menuOpen = false"
      ></MenuLateral>
      <b-modal
        v-model="mostrarModalGrupos"
        :active.sync="mostrarModalGrupos"
        hide-footer
        centered
        title="Grupo do Veículo"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(grupo, index) in escolherGrupoComPlaca
              ? gruposPorTipo
              : grupos"
            :key="index"
            :variant="destacaGrupo(grupo)"
          >
            <div class="ContainerPai">
              <b-col cols="1 ContainerRadio">
                <b-form-radio v-model="grupoSelecionado" :value="grupo">
                </b-form-radio>
              </b-col>
              <b-col cols="11">
                <b-col class="grupoNome">{{
                  grupo.value ? grupo.value.label : grupo.label
                }}</b-col>
                <b-col>{{
                  grupo.value ? grupo.value.descricao : grupo.descricaoProdutos
                }}</b-col>
              </b-col>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-row>
          <b-col class="d-flex justify-content-end mt-3">
            <b-button @click="fecharModalGrupos()" pill variant="success">{{
              acaoBotao
            }}</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import topoInterno from './TopoInterno';
import MenuLateral from './MenuLateral';
import colors from './../services/colors';
import service from '../services';
import locale from './../locale';
import { Money } from 'v-money';
import { decryptData } from '../services/decryptData';

export default {
  components: { topoInterno, MenuLateral, Money },
  data() {
    return {
      menuOpen: false,
      carregando: false,
      temaCor: {},
      passo: 0,
      decoded: null,
      form: {
        nome: null,
        celular: null,
        email: null,
        estado: null,
        cidade: null,
        placa: '',
        valor: '',
        valorCobertura: '',
        idGrupo: {},
        veiculo: {
          cilindradas: null,
          resultFipe: {},
        },
      },
      tiposFipe: [],
      tipoFipe: null,
      dicionarioTiposVeiculos: {},
      grupos: [],
      gruposPorTipo: [],
      estados: [],
      estado: null,
      cidades: [],
      errosArray: [],
      temPlaca: true,
      dadosPlaca: {},
      modelosFipeEncontrados: [],
      mostrarModelosFipeEncontrados: false,
      mostrarValoresFipe: false,
      modeloSeleciondo: {},
      grupoSelecionado: {},
      dadosFipeModelo: {},
      showCilindrada: false,
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      valorVeiculo: 0,
      produtosObrigatorios: [],
      produtosOpcionais: [],
      produtosCotacao: [],
      fazerBusca: true,
      mostrarModalGrupos: false,
      escolherGrupoComPlaca: false,
      acaoBotao: 'Escolher',
    };
  },
  created() {
    this.buscaDadosToken();
  },
  mounted() {
    this.buscaDadosGrupos();
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
    this.buscaDadosSelects(
      'domTipoFipe',
      'tiposFipe',
      'nomeTipo',
      'idTipoFipe',
    );
    this.buscarDicionarioTiposVeiculos();
    this.$validator.localize('en', locale);
  },
  watch: {
    estado: function (newValue) {
      if (newValue !== null) {
        this.form.estado = this.estado.valor;
      }
    },
    temPlaca() {
      if (this.temPlaca) {
        this.form.placa = '';
        this.grupoSelecionado = {};
        (this.tipoFipe = null), (this.form.idGrupo = {});
        this.showCilindrada = false;

        this.acaoBotao = 'Escolher';
      } else {
        this.valorVeiculo = 0;
        this.form.placa = '';
        this.form.valor = '';
        this.form.veiculo.cilindradas = null;
        this.form.idGrupo = {};
        this.dadosPlaca = {};
        this.modeloSeleciondo = {};
        this.dadosFipeModelo = null;
        this.form.valorCobertura = null;
        this.form.veiculo.resultFipe = {};
        this.mostrarModelosFipeEncontrados = false;
        this.mostrarValoresFipe = false;
        this.form.valor = '';
        this.showCilindrada = false;
        (this.tipoFipe = null), (this.escolherGrupoComPlaca = false);
        this.grupoSelecionado = {};
        this.acaoBotao = 'Escolher';
      }
    },
    valorVeiculo() {
      this.form.valor = this.converteParaReal(this.valorVeiculo);
      this.form.valorCobertura = this.converteParaReal(this.valorVeiculo);
      this.form.veiculo.resultFipe.preco = this.converteParaReal(
        this.valorVeiculo,
      );
    },
    passo(newValue, oldValue) {
      if (newValue === 1 && oldValue === 2) {
        this.fazerBusca = true;
        this.produtosObrigatorios = [];
        this.produtosOpcionais = [];
        this.produtosCotacao = [];
      }
      if (newValue === 0 && oldValue === 1) {
        this.form.celular = this.form.celular;
      }
    },
    grupoSelecionado(newValue) {
      if (newValue.value) newValue.value && this.trocaGrupo(newValue.value);
      else newValue && this.trocaGrupo(newValue);
    },
    modeloSeleciondo(newValue) {
      this.temPlaca &&
        Object.hasOwn(newValue, 'tipo_modelo') &&
        this.buscarDadosFipeModelo();
    },
  },
  computed: {
    somaValorTotalMes() {
      return this.produtosCotacao.reduce((acc, curr) => {
        if (curr.ismensal) {
          acc += curr.valor;
        }
        return acc;
      }, 0);
    },
    somaValorTotal() {
      return this.produtosCotacao.reduce((acc, curr) => {
        if (!curr.ismensal) {
          acc += curr.valor;
        }
        return acc;
      }, 0);
    },
  },
  methods: {
    converteParaReal(valor) {
      return valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    buscaDadosToken() {
      this.temaCor = colors.getColorsTema();
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        this.form.idEmpresa = this.decoded.id_empresa;
        this.form.idUsuario = this.decoded.id_usuario;
        this.form.idRegional = this.decoded.id_regional;
      }
    },
    verificaPasso() {
      let arrPassos = ['Dados Pessoais', 'Dados do Veículo', 'Produtos'];
      return arrPassos[this.passo];
    },
    popToast(msg, type) {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;
      var arrMsg = msg ? [msg] : this.errosArray;
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: type ? type : 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    async onSubmit(/*evt*/) {
      await this.$validator.validateAll();
      this.validarCampos();

      if (this.errosArray.length === 0) {
        this.passo < 2 && this.passo++;
        this.passo === 2 && this.fazerBusca && this.buscar();
      } else {
        this.popToast();
      }
    },
    onReset(evt) {
      evt.preventDefault();
    },
    voltar() {
      this.passo--;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    validarCampos() {
      this.errosArray = this.$validator.errors.all();
      const erros = {
        valorInvalido: 'O Valor do Veículo é inválido',
        grupoAusente: 'O campo Grupo do Veículo é obrigatório',
        modeloNaoConfirmado:
          'Por favor, confirme o modelo do veículo ou clique em "Nenhum Desses"',
      };

      let semErros = true;

      if (
        this.passo === 1 &&
        !this.temPlaca &&
        +this.form.valor.slice(3).replace(',', '.') <= 0
      ) {
        this.errosArray.push(erros.valorInvalido);
        semErros = false;
      }

      if (
        this.passo === 1 &&
        this.temPlaca &&
        this.form.placa &&
        !Object.hasOwn(this.modeloSeleciondo, 'tipo_modelo')
      ) {
        this.errosArray.push(erros.modeloNaoConfirmado);
        semErros = false;
      }

      if (this.passo === 1 && !Object.hasOwn(this.form.idGrupo, 'label')) {
        this.errosArray.push(erros.grupoAusente);
        semErros = false;
      }

      if (semErros) {
        this.errosArray = this.errosArray.filter(
          (error) =>
            error !== erros.valorInvalido ||
            error !== erros.grupoAusente ||
            erros.modeloNaoConfirmado,
        );
      }
    },
    buscaDadosGrupos() {
      service.getAll('Grupo', {}, 'list/' + this.form.idEmpresa).then((res) => {
        res.data.forEach((element) => {
          if (element.deletedAt == null)
            this.grupos.push({
              value: {
                id: element.idGrupo,
                label: element.nomeGrupo,
                idTipoFipe: element.idTipoFipe,
                descricao: element.descricao,
              },
              valor: element.nomeGrupo,
              text: element.nomeGrupo,
            });
        });
      });
    },
    destacaGrupo(grupo) {
      if (
        this.grupoSelecionado.value &&
        this.grupoSelecionado.value.id === grupo.value.id
      ) {
        return 'success';
      } else {
        return '';
      }
    },
    trocaGrupo(newValue) {
      if (Object.hasOwn(newValue, 'label')) {
        this.form.idGrupo.id = newValue.id;
        this.form.idGrupo.idTipoFipe = newValue.idTipoFipe;
        this.form.idGrupo.label = newValue.label;
        if (this.form.idGrupo.idTipoFipe === 2) {
          this.showCilindrada = true;
        } else {
          this.showCilindrada = false;
        }
      } else {
        this.form.idGrupo = {};
      }
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf' || model == 'domTipoFipe') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        res.data = decryptData(res.data);
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        this[array].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
        );
        this.carregando = false;
      });
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.estado.id}`)
        .then((res) => {
          res.data = decryptData(res.data);
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
    keymonitorPlaca: function () {
      if (this.form.placa.length == 8) {
        this.buscaDadosPlaca();
      }
    },
    buscaDadosPlaca() {
      if (this.form.placa.length === 8) {
        this.carregando = true;
        this.form.idGrupo = {};
        this.showCilindrada = false;
        this.modeloSeleciondo = {};
        service
          .post('CotacaoAberta', `BuscaDadosPlaca`, { placa: this.form.placa })
          .then((res) => {
            if (
              res.data.mensagemRetorno.includes(
                'Nenhum veículo foi encontrado',
              ) ||
              !res.data.fipe.dados ||
              !res.data.extra ||
              res.data.fipe.dados.length === 0
            ) {
              this.form.placa = '';
              this.temPlaca = false;
              this.carregando = false;
              this.popToast(
                `Nenhum modelo foi encontrado com a placa fornecida`,
                'danger',
              );
            } else {
              this.dadosPlaca = res.data;
              this.modelosFipeEncontrados = res.data.fipe.dados
                .map((element) => {
                  element.selecionado = false;
                  return element;
                })
                .sort((a, b) =>
                  a.score > b.score ? -1 : a.score < b.score ? 1 : 0,
                );
              this.pegaIdTipoFipe();
              this.carregando = false;
              this.mostrarModelosFipeEncontrados = true;
              this.escolherGrupoComPlaca = false;
            }
          })
          .catch((e) => {
            this.form.placa = '';
            this.temPlaca = false;
            this.carregando = false;
            this.popToast(
              `Nenhum modelo foi encontrado com a placa fornecida`,
              'danger',
            );
          });
      }
    },
    buscarDicionarioTiposVeiculos() {
      service
        .post('dicionarios', 'buscar-dicionario-tipos-veiculos', {})
        .then((res) => {
          this.dicionarioTiposVeiculos = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pegaIdTipoFipe() {
      const tipoVeiculo =
        this.dicionarioTiposVeiculos[this.dadosPlaca.extra.tipo_veiculo];
      this.reatribuirTipoFipe(tipoVeiculo.codigoTipo);
    },
    reatribuirTipoFipe(id) {
      const tipo = this.tiposFipe.filter((tipo) => id === tipo.value.id);
      this.tipoFipe = tipo[0].value;
    },
    buscarDadosFipeModelo() {
      this.carregando = true;
      const modelo = this.modeloSeleciondo;
      modelo.codigoTipoVeiculo = modelo.tipo_modelo;
      modelo.anoModelo = this.dadosPlaca.anoModelo;
      service
        .post('CotacaoAberta', 'BuscaDadosFipeModelo', modelo)
        .then((res) => {
          this.dadosFipeModelo = res.data;
          this.form.valor = res.data.preco;
          this.form.valorCobertura = this.form.valor;
          this.form.veiculo.resultFipe.preco = this.form.valor;
          if (
            res.data.tipo === 2 &&
            this.dadosPlaca.extra &&
            this.dadosPlaca.extra.cilindradas
          ) {
            this.form.veiculo.cilindradas = this.dadosPlaca.extra.cilindradas;
          }
          this.form.valor = res.data.preco;
          this.buscaGrupoMarcaModelo(res.data);
          this.mostrarValoresFipe = true;
        })
        .catch((error) => {
          this.carregando = false;
          this.mostrarValoresFipe = false;
        });
    },
    buscaGrupoMarcaModelo(data) {
      const idMarca = data.id_marca;
      const idModelo = data.id_modelo;
      const tipo = data.tipo;
      const _idEmpresa = this.form.idEmpresa;
      const objBuscaGrupo = { idEmpresa: _idEmpresa, idMarca, idModelo, tipo };
      service
        .post('CotacaoAberta', 'buscaGrupoMarcaModelo', objBuscaGrupo)
        .then((res) => {
          if (res.data.length === 1) {
            this.trocaGrupo(res.data[0]);
            this.reatribuirTipoFipe(res.data[0].idTipoFipe);
            this.carregando = false;
          } else {
            this.carregando = false;
            !Object.hasOwn(this.form.idGrupo, 'label') &&
              this.abrirModalGrupos(null, res.data);
          }
        })
        .catch((error) => {
          this.carregando = false;
        });
    },
    abrirModalGrupos(tipo, gruposEncontrados) {
      if (!tipo) tipo = this.form.idTipoFipe;
      this.form.idGrupo = {};
      const separarGrupos =
        gruposEncontrados && gruposEncontrados.length !== 0 ? true : false;
      if (separarGrupos) {
        this.gruposPorTipo = gruposEncontrados.filter(
          (g) => g.idTipoFipe === tipo,
        );
      } else {
        this.gruposPorTipo = this.grupos.filter(
          (g) => g.value.idTipoFipe === tipo.id,
        );
      }
      this.popToast(
        `Por favor, selecione o Grupo do Véiculo manualmente.`,
        'warning',
      );
      this.escolherGrupoComPlaca = true;
      this.mostrarModalGrupos = true;
    },
    fecharModalGrupos() {
      (this.mostrarModalGrupos = false), (this.acaoBotao = 'Trocar');
    },
    removerCilindradas() {
      this.form.veiculo.cilindradas = null;
    },
    buscar() {
      this.carregando = true;
      this.form.idGrupo.idTipoFipe !== 2 && this.removerCilindradas();
      service
        .post('Cotacao', 'BuscaProdutos', this.form)
        .then((res) => {
          this.carregando = false;
          if (res.data.length === 0) {
            this.popToast(
              'Nenhum Produto Encontrado para essa faixa',
              'danger',
            );
            this.passo--;
          } else {
            this.fazerBusca = false;
            this.separarProdutos(res.data);
          }
        })
        .catch((error) => {
          this.carregando = false;
        });
    },
    separarProdutos(data) {
      this.produtosObrigatorios = data
        .filter((produto) => produto.obrigatorio)
        .map((produto) => {
          produto.excluido = false;
          return produto;
        });
      this.produtosOpcionais = data
        .filter((produto) => !produto.obrigatorio)
        .map((produto) => {
          produto.selecionado = false;
          return produto;
        });
      this.produtosDaCotacao();
    },
    processarProdutos(produto) {
      produto.selecionado = !produto.selecionado;
      this.linkaProdutos(produto);
      this.sobrepoeProdutos(produto);
      this.produtosDaCotacao();
    },
    linkaProdutos(prod) {
      const produtosLinkados = prod.produtosLinkados.split(',');
      produtosLinkados &&
        produtosLinkados.map((id) => {
          this.produtosObrigatorios = this.produtosObrigatorios.map((produto) =>
            produto.id_produto === +id
              ? { ...produto, excluido: prod.selecionado }
              : produto,
          );
          this.produtosOpcionais = this.produtosOpcionais.map((produto) =>
            produto.id_produto === +id
              ? { ...produto, selecionado: prod.selecionado }
              : produto,
          );
        });
    },
    sobrepoeProdutos(prod) {
      const produtosSobrepostos = prod.sobreporOutrosProdutos.split(',');
      produtosSobrepostos &&
        produtosSobrepostos.map((id) => {
          this.produtosObrigatorios = this.produtosObrigatorios.map((produto) =>
            produto.id_produto === +id
              ? { ...produto, excluido: prod.selecionado ? true : false }
              : produto,
          );
          this.produtosOpcionais = this.produtosOpcionais.map((produto) =>
            produto.id_produto === +id
              ? { ...produto, selecionado: false }
              : produto,
          );
        });
    },
    produtosDaCotacao() {
      this.produtosCotacao = this.produtosObrigatorios
        .concat(this.produtosOpcionais)
        .filter((produto) => produto.excluido === false || produto.selecionado);
    },
    async salvar() {
      this.form.produtos = this.produtosCotacao;
      this.form.dadosPlaca = this.dadosPlaca || null;
      this.form.modelo = this.modeloSeleciondo || null;
      this.form.dadosFipeModelo = this.dadosFipeModelo || null;
      this.form.grupoSeleciondo = this.form.idGrupo || null;
      this.carregando = true;
      service
        .post('PreCotacao', 'CadastrarPreCotacao', this.form)
        .then((res) => {
          this.carregando = false;
          this.$router.push('dashboard?page=4');
        })
        .catch((error) => {
          this.carregando = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.custom-control-label {
  width: 100%;
}

.badge {
  font-size: 100%;
}
.NovaCotacao {
  padding-bottom: 80px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
.grupoNome {
  font-size: 1.2em;
  font-weight: 700;
}
.grupoDescricao {
  font-size: 0.8em;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.iconeErro {
  color: red;
  font-size: 56px;
  margin: 20px 0;
}
.iconeSucesso {
  color: green;
  font-size: 56px;
  margin: 20px 0;
}
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}
.btn {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.topoForm {
  border-radius: 10px;
  border-left: 10px solid rgb(52, 138, 167);
  padding: 10px 20px;
  -webkit-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  margin: 10px;
  text-align: center;
  color: rgb(52, 138, 167);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.conteudoInterno .listaerros {
  font-size: 12px;
}

.restricoes .custom-checkbox:last-child {
  margin-top: 30px;
}

.valorprod {
  color: #000;
  font-weight: bolder;
  font-family: 'roboto';
}

.ContainerPai {
  display: flex;
  flex-direction: row;
}

.ContainerRadio {
  display: flex;
  align-items: center;
}
</style>
